import { template as template_c1db83874b4a45e39f06bf67ad6353ec } from "@ember/template-compiler";
const SidebarSectionMessage = template_c1db83874b4a45e39f06bf67ad6353ec(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

import { template as template_1871ef54ce134c4293d7cf96ca5f1952 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_1871ef54ce134c4293d7cf96ca5f1952(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;

import { template as template_761b3559c9ff49f2ac5fb5e8bb4bd3c9 } from "@ember/template-compiler";
const FKLabel = template_761b3559c9ff49f2ac5fb5e8bb4bd3c9(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

import { template as template_718cb95ee6174f1e8c86bdf7bfe3a09e } from "@ember/template-compiler";
const FKText = template_718cb95ee6174f1e8c86bdf7bfe3a09e(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;

import { template as template_f24479e2f23f4ac59fea8593743d3bee } from "@ember/template-compiler";
import { hash } from "@ember/helper";
const DropdownItem = template_f24479e2f23f4ac59fea8593743d3bee(`
  <li class="dropdown-menu__item" ...attributes>{{yield}}</li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownDivider = template_f24479e2f23f4ac59fea8593743d3bee(`
  <li ...attributes><hr class="dropdown-menu__divider" /></li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
const DropdownMenu = template_f24479e2f23f4ac59fea8593743d3bee(`
  <ul class="dropdown-menu" ...attributes>
    {{yield (hash item=DropdownItem divider=DropdownDivider)}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default DropdownMenu;

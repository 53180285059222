import { template as template_8370b3b4cd15434788efe15cd713a9b1 } from "@ember/template-compiler";
const WelcomeHeader = template_8370b3b4cd15434788efe15cd713a9b1(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
